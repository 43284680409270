import React from 'react'
import { Helmet } from 'react-helmet'

export default function SEO() {

	const websiteTitle = `Développeur web & mobile freelance React & Node Js Toulouse`
	const websiteDescription = `Freelance ingénieur FullStack (FrontEnd + BackEnd), développeur web et mobile, expert React, Node js, React Native, création site web & application mobile`

	return (
		<Helmet htmlAttributes={{
			lang: 'fr',
		}}>
			<meta charset="utf-8" />
			<meta http-equiv="Content-Type" content="text/html;charset=UTF-8" />
			<meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />

			/* Font awesome - defer loading */
			<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css" integrity="sha512-+4zCK9k+qNFUR5X+cKL9EIR+ZOhtIloNl9GIKS57V1MyNsYpYcUrUeQc9vNfzsWfV28IaLL3i96P9sdNyeRssA==" crossorigin="anonymous" media="print" onload="this.media='all'; this.onload=null;" />

			{/* <link href="https://fonts.googleapis.com/css?family=Montserrat:400,700,200" rel="stylesheet" /><link href="https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap" rel="stylesheet"> */}
			{/* <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Catamaran:wght@300;800&family=Open+Sans&display=swap" media="print" onload="this.media='all'; this.onload=null;"></link> */}

			{/* <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap" media="print" onload="this.media='all'; this.onload=null;"></link> */}
			{/* <link href="https://fonts.googleapis.com/css2?family=Catamaran:wght@300;800&family=Open+Sans&display=swap" rel="stylesheet" />
				<link href="https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap" rel="stylesheet" /> */}

			{/* <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" /> */}
			<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" media="print" onload="this.media='all'; this.onload=null;"></link>
			{/* <script async src="https://cdnjs.cloudflare.com/ajax/libs/wow/1.1.2/wow.min.js"></script> */}

			<script async src="https://unpkg.com/smoothscroll-polyfill/dist/smoothscroll.min.js"></script>

			<title>{websiteTitle}</title>


			<meta name="description" content={websiteDescription} />

			<meta http-equiv="X-UA-Compatible" content="ie=edge" />

			<meta name="keywords" content="développeur,développement,web,mobile,fullstack,frontend,backend,application,React,Node,js,React.js,node.js,JavaScript,site web,application mobile,freelance,ingénieur,expert,React Native,Java,Toulouse,Airbus,expérimenté,livraison,mise en relation" />

			{/* Open Graph / Facebook */}
			<meta property="og:type" content="website" />
			<meta property="og:url" content="https://vincentandrieu.fr/" />
			<meta property="og:title" content={websiteTitle} />
			<meta property="og:description" content={websiteDescription} />
			<meta property="og:image" content="https://vincentandrieu.fr/og_image.jpg" />

			{/* Twitter */}
			<meta property="twitter:card" content="summary_large_image" />
			<meta property="twitter:url" content="https://vincentandrieu.fr/" />
			<meta property="twitter:title" content={websiteTitle} />
			<meta property="twitter:description" content={websiteDescription} />
			<meta property="twitter:image" content="https://vincentandrieu.fr/og_image.jpg" />


			{/* <script>
					{`
					function animateCSS(selector, animation, callback) {
						const prefix = 'animate__'
						const animationName = prefix + animation
						const node = document.querySelector(selector);

						node.classList.add(prefix+'animated', animationName);

						// When the animation ends, we clean the classes and resolve the Promise
						function handleAnimationEnd() {
							node.classList.remove(prefix+'animated', animationName);
							callback()
							resolve('Animation ended');
						}
					node.addEventListener('animationend', handleAnimationEnd, {once: true });
					}
				`}
				</script> */}

			{/* needed for vanta.js */}
			{/* <script src="https://cdnjs.cloudflare.com/ajax/libs/three.js/r121/three.min.js"></script> */}

			{/* needed for vanta.js 
			<script src="https://cdnjs.cloudflare.com/ajax/libs/three.js/r121/three.min.js"></script>
			<script src="https://cdn.jsdelivr.net/npm/vanta@0.5.21/dist/vanta.waves.min.js"></script>
			<script>
				{`
					console.log("HEYYY");
					/*
let interval = setInterval(() => {
	console.log("start iteration");
	if(document.querySelector('#test-vanta-js')) {
		console.log("#test-vanta-js found");
		if(document.querySelector('#test-vanta-js').querySelector('canvas')) {
			console.log("canvas found");
		} else {
			console.log("canvas not found, executing...");
			VANTA.WAVES({
				el: '#test-vanta-js',
				mouseControls: true,
				touchControls: true,
				gyroControls: false,
				minHeight: 200.00,
				minWidth: 200.00,
				scale: 1.00,
				scaleMobile: 1.00,
				color: 0x42247a,
				shininess: 21.00,
				waveHeight: 12.00,
				waveSpeed: 1,
				zoom: 0.97
			});
			clearInterval(interval)
			console.log("interval stopped");
		}
	} else {
		console.log("#test-vanta-js not found");
	}
},100)
				`}
			</script>*/}

		</Helmet>
	)
}
