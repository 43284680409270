import React, { useState, useEffect } from "react";
import { Button, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col, Card, CardImg, CardBody, CardTitle, CardText, Badge } from "reactstrap";
import TabContainer from "./TabContainer";
import { useInView } from "react-intersection-observer";
import SectionSeparator from "components/SectionSeparator.js";
import ArrowLink from "components/ArrowLink";
import scrollToElement from "../utils/scrollToElement"

function Service(props) {

	const [refCard, cardIsInView, entry] = useInView({ threshold: 0.05 })

	const [nativeCssTransitionCardX, setNativeCssTransitionCardX] = useState(-100)
	const [nativeCssTransitionCardOpacity, setNativeCssTransitionCardOpacity] = useState(0)

	useEffect(() => {
		if (cardIsInView) {
			setNativeCssTransitionCardX(0)
			setNativeCssTransitionCardOpacity(1)
		}
	}, [cardIsInView]);


	return (
		<Row className="mb-5">
			<Col className="ml-auto mr-auto text-center">
				<div ref={refCard}>
					<div className="card" style={{ opacity: nativeCssTransitionCardOpacity, transition: 'opacity 0.4s, transform 0.4s', transform: `translateX(${nativeCssTransitionCardX}px)` }}>
						<div className="card-body">
							{props.children}
						</div>
					</div>
				</div>
			</Col>
		</Row>
	)
}

function ServicesPage(props) {
	return (
		<TabContainer {...props} title="Services et tarifs" subtitle="Ce que je peux faire pour vous" headerProfile='profile'>

			<SectionSeparator
				title='Services'
				subtitle='Ce que je peux faire pour vous'
			/>

			<div className={`section`}>
				<Container>
					<Row>
						<Col>
							<h5 className="pitch subtitle pb-3" style={{ marginBottom: '3em', whiteSpace: 'pre-wrap', fontSize: '1.5em' }}>
								Je vous accompagne en freelance sur les activités suivantes :
						</h5>
						</Col>
					</Row>
					<Service>
						<i className="fas fa-code gradient-icon" style={{ fontSize: '6em' }}></i>
						<h2 className="pitch title mb-5">Ingénierie logicielle & Développement full stack</h2>
						<h5 className="pitch subtitle pb-3" style={{ marginBottom: '3em', whiteSpace: 'pre-wrap' }}>
							Je fais de vos idées une application concrète.
									</h5>
						<p style={{ textAlign: 'left', fontSize: '1.5em' }}>Grâce à mes compétences en ingénierie de développement full stack, je suis en mesure de créer pour vous n'importe quel logiciel, qu'il s'agisse d'une application web, d'une application mobile, d'un logiciel de bureau, d'une API ou d'une infrastructure métier back-end.</p>
						<p style={{ textAlign: 'left', fontSize: '1.5em' }}>Mes années passées au poste de chef de projet me permettent une compréhension accrue des enjeux qui gravitent autour de la gestion de cette activité et de la satisfaction client.</p>
						<p style={{ textAlign: 'left', fontSize: '1.5em' }}>Vous souhaitez choisir un langage de programmation particulier ?</p>
						<p style={{ textAlign: 'left', fontSize: '1.5em' }}>Voir la section "Compétences" juste en-dessous pour découvrir les technologies que je maîtrise le plus (liste non exhaustive).</p>
					</Service>
					<Service>
						<i className="fas fa-sitemap gradient-icon" style={{ fontSize: '6em' }}></i>
						<h2 className="pitch title mb-5">Architecture de solution logicielle</h2>
						<h5 className="pitch subtitle pb-3" style={{ marginBottom: '3em', whiteSpace: 'pre-wrap' }}>
							Lancez votre projet avec les outils et langages les mieux adaptés.
									</h5>
						<p style={{ textAlign: 'left', fontSize: '1.5em' }}>Le choix des technologies à utiliser pour réaliser un projet est crucial.</p>
						<p style={{ textAlign: 'left', fontSize: '1.5em' }}>De mauvais choix peuvent causer des ralentissements dans votre application, des difficultés à implémenter des évolutions futures, des coûts de services tiers exponentiels, des problèmes de sécurité et de confidentialité, ainsi que diverses instabilités.</p>
						<p style={{ textAlign: 'left', fontSize: '1.5em' }}>Je fournis une étude complète sur l'ensemble des technologies, services, infrastructures et langages de programmation les plus pertinents, robustes et efficaces à mettre en place pour construire votre projet précis.</p>
					</Service>
					<Service>
						<i className="fas fa-graduation-cap gradient-icon" style={{ fontSize: '6em' }}></i>
						<h2 className="pitch title mb-5">Coaching et Formation à la programmation</h2>
						<h5 className="pitch subtitle pb-3" style={{ marginBottom: '3em', whiteSpace: 'pre-wrap' }}>
							Formations en présentiel ou en vidéoconférence.
						</h5>
						<p style={{ textAlign: 'left', fontSize: '1.5em' }}>Formations existantes pour React.js et pour les basiques du développement web (HTML/CSS/JavaScript).</p>
						<p style={{ textAlign: 'left', fontSize: '1.5em' }}>Possibilité de créer des formations pour d'autres compétences parmi celles listées dans la section "Compétences" ci-dessous.</p>
						<p style={{ textAlign: 'left', fontSize: '1.5em' }}>Formation en présentiel ou en vidéoconférence, avec déplacement possible dans toute la France.</p>
					</Service>
				</Container>
			</div>
			<div className={`section pt-0`} style={{ marginBottom: 90 }}>
				<Container>
					<Row>
						<Col className="ml-auto mr-auto">
							<h2 className="pitch title mb-5 text-center">Mes tarifs</h2>
							<p style={{ fontSize: '1.5em' }}>Mes tarifs sont définis au cas par cas, selon la nature de la mission et des activiés qu'elle inclut.</p>
							<p style={{ fontSize: '1.5em' }}>À partir de 420 € par jour.</p>
							<p style={{ fontSize: '1.5em' }}>Je peux vous établir une estimation ou un devis gratuitement. Pour cela, <ArrowLink onClick={() => scrollToElement('#contactme-anchor', false)}>cliquez ici</ArrowLink> puis décrivez-moi votre besoin ou votre projet. Je ne manquerai pas de revenir rapidement vers vous pour affiner votre besoin et vous fournir une estimation.</p>

						</Col>
					</Row>
				</Container>
			</div>

		</TabContainer>

	);
}

export default ServicesPage;
