import React, { useState, useEffect, useRef } from "react";
import { Button, Link, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col, Card, CardImg, CardBody, CardTitle, CardText, Badge } from "reactstrap";
import TabContainer from "./TabContainer";
import { useInView } from "react-intersection-observer";
import SectionSeparator from "components/SectionSeparator.js";
import ArrowLink from "components/ArrowLink";
import GradientButton from "components/GradientButton";
import copyTextToClipboard from "../utils/clipboard"

function ContactSection(props) {

	const clipboardCopyFeedback = React.useRef(null);

	const email = "vincent@vincentandrieu.fr"

	const copyEmail = () => {
		copyTextToClipboard(email)
		console.log("REF", clipboardCopyFeedback);
		console.log("REF.current", clipboardCopyFeedback.current);
		clipboardCopyFeedback.current.style.transition = 'opacity 0s'
		clipboardCopyFeedback.current.style.opacity = 1
		setTimeout(() => {
			clipboardCopyFeedback.current.style.transition = 'opacity 2s'
			clipboardCopyFeedback.current.style.opacity = 0
		}, 1000);
	}

	return (
		<TabContainer {...props} className='pb-0'>

			<div style={{ background: 'linear-gradient(125deg, rgba(104,83,255,1) 0%, rgba(212,85,254,1) 100%)' }}>

				{/* vague de séparation */}
				<div className='mb-0 mb-md-3 mb-lg-5' id="contactme-anchor" style={{ transform: 'translateY(-5px)' }}>
					<img alt="vague de séparation" style={{ width: '100%' }} src={require("assets/img/wave-separator-1-white-paddingtop.svg")} />
				</div>

				<div className={`section text-white`} style={{ marginBottom: 0, paddingBottom: '5vw', backgroundColor: 'rgba(255,255,255,0)' }}>

					<Container>
						<Row>
							<Col className="ml-auto mr-auto">
								<h2 className="pitch title mb-5 text-center text-white">Discutons de votre projet</h2>

								<h4 className="text-white">Que vous souhaitiez discuter d'une collaboration ou simplement me poser une question, n'hésitez pas à m'envoyer un email :</h4>

								<div className=' text-center'>

									{/* Mobile - small text (else it is larger than screen) */}
									<div className='d-block d-sm-none mt-5 mb-3'>
										<i className="far fa-envelope mb-3" style={{ transform: 'translateY(3px) scale(1.3)', fontSize: 40 }}></i><br />
										<h3 style={{ color: 'white', fontSize: 20, fontWeight: 700 }}><a title="Envoyer un email" className='text-white' href={`mailto:${email}`}>{email}</a></h3>
									</div>

									{/* Not mobile */}
									<div className='d-none d-sm-block mt-5 mb-3'>
										<h3 style={{ color: 'white', fontWeight: 700 }}><i className="far fa-envelope mr-4" style={{ transform: 'translateY(3px) scale(1.3)' }}></i><a title="Envoyer un email" className='text-white' href={`mailto:${email}`}>{email}</a></h3>
									</div>

									<Button title="Copier l'adresse email" className='btn mt-4' color='primary' size='lg' style={{ backgroundColor: 'white', color: '#2C2C2C', fontSize: '1.2em', paddingLeft: 33, paddingRight: 33 }} onClick={copyEmail}>
										Copier l'adresse email
									</Button>
									<div ref={clipboardCopyFeedback} style={{ opacity: 0, marginTop: -8 }}>
										✅ Adresse copiée dans le presse papiers
									</div>

									<a title="Envoyer un email" target="_blank" href={`mailto:${email}`} className='btn btn btn-primary btn-lg mt-4' style={{ backgroundColor: 'white', color: '#2C2C2C', fontSize: '1.2em', paddingLeft: 33, paddingRight: 33 }}>
										Envoyer un email maintenant
									</a>
								</div>

							</Col>
						</Row>
						<div style={{ height: 200 }}>

						</div>
						<Row>
							<Col sm="6" style={{ textAlign: 'left' }}>
								<div className="copyright" id="copyright">
									<div>Vincent Andrieu</div>
									<div>VA Solutions</div>
									<div>SIRET 85048603600013</div>
									<div>13 Rue Sainte Ursule</div>
									<div>31000 Toulouse</div>
									<div>{email}</div>
								</div>
							</Col>
							<Col sm="6" className='text-center mt-3 text-sm-right mt-sm-0'>
								<div className="copyright" id="copyright">
									© {new Date().getFullYear()} VA Solutions
								</div>
							</Col>
						</Row>
					</Container>
				</div>

			</div>

		</TabContainer>

	);
}

export default ContactSection;
