import React from "react";
import scrollToElement from '../../utils/scrollToElement'
import { Button, Container, Row, Col } from "reactstrap";
import TabBar from "components/TabBar";

const ContactButton = (props) => (
	<Button
		// className="btn btn-me-contacter animate__animated animate__pulse wow"
		// data-wow-delay="1s"
		// data-wow-duration="2s"
		color="primary"
		size="md"
		style={{ /*boxShadow: 'rgba(0,0,0,0.3) 0px 0px 10px 0px',*/ background: 'linear-gradient(125deg, rgba(104,83,255,1) 0%, rgba(212,85,254,1) 100%)', margin: 0, height: 50, padding: '0px 22px', ...props.style }}
		onClick={() => scrollToElement('#contactme-anchor', false)}>
		Me contacter
	</Button>
)
const CvPdfButton = (props) => (

	<a
		title="Télécharger le CV au format PDF"
		className='btn btn-primary btn-md'
		target='_blank'
		href='/Vincent_ANDRIEU.pdf'
		color="primary"
		size="md"
		style={{ /*boxShadow: 'rgba(0,0,0,0.3) 0px 0px 10px 0px',*/ background: 'white', border: '1px solid #555', color: '#555', margin: 0, height: 50, padding: '2px 22px 0px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', ...props.style }}
	>
		<div>
			<img style={{ height: '30px', marginRight: 10, transform: 'translateY(-1px)' }} src={require("assets/img/pdf.svg")} alt="PDF icon" />
			<span>CV</span>
		</div>
	</a>
)

function ProfilePageHeader(props) {

	const verticalGap = 20

	const showStoryCircle = false

	return (
		<>
			<Container style={{ paddingTop: 20 }}>

				<Row>
					<Col className='profile-header-container' style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'stretch' }}>
						<div className='profile-picture-width-controller'>
							<div
								style={{ position: 'relative' }}
								className="profile-page-photo-container" onClick={
									e => {
										// document.querySelector('h3.title').innerHTML = (new Date()).getTime() + '; ' + e.target + '; ' + e.target.closest('.profile-page-photo-container') + ' ' + e.target.closest('.profile-page-photo-container').classList
										// console.log(e.target, e.target.closest('.profile-page-photo-container'), e.target.closest('.profile-page-photo-container').classList)
										// console.log("photo click")
										// e.target.closest('.profile-page-photo-container').classList.remove('animate__animated', 'animate__zoomInDown');
										// e.target.closest('.profile-page-photo-container').classList.toggle('scale-up')
									}
								}>

								{/* Ici volontairement on n'utilise pas le <Img> de Gatsby car même en désactivant les transitions d'opacité et le lazy loading, il y a une latence (l'image arrive tard) */}
								{/* Par contre on utilise le props.gqlImages.vincent_profile_picture de Gatsby pour tirer parti de l'optimisation de l'image */}
								<img src={props.gqlImages.vincent_profile_picture.childImageSharp.fluid.src} style={{ borderRadius: '50%', transform: showStoryCircle ? 'scale(0.9)' : 'scale(1)', background: showStoryCircle ? 'unset' : 'linear-gradient(125deg, rgba(104,83,255,1) 0%, rgba(212,85,254,1) 100%)', boxShadow: 'rgba(78, 6, 107, 0.19) 0px 0px 10px 0px' }} alt="photo de profil" />
								{/* <Img loading='eager' imgStyle={{ borderRadius: '50%', transform: 'scale(0.9)', transition: 'none' }} fluid={props.gqlImages.vincent_profile_picture3.childImageSharp.fluid} /> */}

								{showStoryCircle && (
									<div className="storyCircle" style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}>
										<img style={{ width: '100%', height: '100%' }} src={require("assets/img/story.svg")}></img>
									</div>
								)}
							</div>
						</div>
						<div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', paddingLeft: 0 }}>
							<h2 className="title pitch" style={{ textAlign: 'left', fontSize: '1.825em', marginBottom: 0, padding: 0, lineHeight: '1.4em' /* styles to make it look like h3 */ }}>Vincent Andrieu</h2>
							<h1 className="pitch" style={{ textAlign: 'left', fontWeight: 300, fontSize: '1.2em', lineHeight: '1.61em', marginBottom: 0, marginTop: verticalGap / 2, padding: 0 /*Styles to make it look like a <p>*/ }}>Ingénieur Logiciel Full-Stack Freelance à Toulouse</h1>

							<div className="d-none d-sm-block">
								<ContactButton style={{ marginTop: verticalGap, fontSize: 'initial' }} />
								<CvPdfButton style={{ marginLeft: 10, marginTop: verticalGap, fontSize: 'initial' }} />
							</div>
						</div>
					</Col>
				</Row>

				<Row className="mt-4 mt-sm-5">
					<Col>
						<div className='bio-items' style={{ fontSize: '1.2em' }}>
							<div className='bio-item'>💼 Freelance</div>
							<div className='bio-item'>✅ {(new Date()).getFullYear() - 2008} ans d’expérience</div>
							<div className='bio-item'>👨‍💻 Software Engineer / Technical Leader</div>
							<div className='bio-item'>🔥 Développeur Full-Stack</div>
							<div className='bio-item'>🏆 Expert Node, React, Java et React Native</div>
							<div className='bio-item'>👨‍🎓 Formateur</div>
							<div className='bio-item'>🚀 Créateur de 3 startups</div>
							<div className='bio-item'>📚 30+ projets réalisés</div>
						</div>
					</Col>
				</Row>

				<Row className="d-flex d-sm-none mt-5">
					<Col className='pr-1' xs="8">
						<ContactButton style={{ width: '100%', fontSize: 'initial' }} />
					</Col>
					<Col className='pl-1' xs="4">
						<CvPdfButton style={{ width: '100%', fontSize: 'initial', padding: '2px 7px 0px' }} />
					</Col>
				</Row>

				<Row className="mt-5">
					<Col>
						<h4 className='mt-0'>
							Je développe des applications web et mobiles, modernes, robustes et performantes pour les entreprises de toutes tailles.
						</h4>
					</Col>
				</Row>
			</Container>

			<TabBar onTabChange={props.onTabChange} tabs={props.tabs} />


		</>
	);
}

export default ProfilePageHeader;
