import React, { useState, useEffect, useRef } from "react";
import PageLayout from "views/PageLayout.js";

import ProfilePageHeaderInstagramStyle from "components/Headers/ProfilePageHeaderInstagramStyle";
import HomeTab from "views/HomeTab.js";
import PortfolioPage from "views/PortfolioPage.js";
import AboutMePage from "views/AboutMePage.js";
import ServicesPage from "views/ServicesPage.js";
import SkillsPage from "views/SkillsPage.js";
import TodoPage from "views/TodoPage.js";
import ContactSection from "views/ContactSection.js";

export default (props) => {

	const [currentTab, setCurrentTab] = useState(0)

	React.useEffect(() => {
		document.body.classList.add("landing-page");
		document.body.classList.add("profile-page");
		document.body.classList.add("sidebar-collapse");
		document.documentElement.classList.remove("nav-open");
		return function cleanup() {
			document.body.classList.remove("landing-page");
			document.body.classList.remove("profile-page");
			document.body.classList.remove("sidebar-collapse");
		};
	}, []);

	const onTabChange = tabIndex => {
		setCurrentTab(tabIndex)
	}

	const IconHomeWithGradient = () => (
		<svg style={{ transform: 'scale(1, -1) translateY(2px)', height: '0.9em' }} viewBox='0 -35 580 455'>
			<linearGradient id="gradient">
				<stop offset="0%" stop-color="rgb(104, 83, 255)" />
				<stop offset="100%" stop-color="rgb(212, 85, 254)" />
			</linearGradient>
			<path fill="url(#gradient)" d="M280.37 299.74c2.09082 1.68555 4.76562 2.69434 7.6582 2.69434s5.55078 -1.00879 7.6416 -2.69434l184.33 -151.74v-164c0 -8.83105 -7.16895 -16 -16 -16l-112.02 0.30957c-8.83105 0 -16.001 7.16895 -16.001 15.999c0 0.0175781 0 0.0341797 0.000976562 0.0517578 v95.6396c0 8.83105 -7.16992 16 -16 16h-64c-8.83105 0 -16 -7.16895 -16 -16v-95.71c0 -8.80371 -7.12695 -15.9561 -15.9209 -16l-112.06 -0.290039c-8.83105 0 -16 7.16895 -16 16v163.89zM571.6 196.53c2.70703 -2.20117 4.42578 -5.56152 4.42578 -9.31836 c0 -2.88867 -1.02246 -5.54004 -2.72559 -7.6123l-25.5 -31c-2.20117 -2.66309 -5.53418 -4.35059 -9.25684 -4.35059c-2.90332 0 -5.56641 1.0332 -7.64258 2.75098l-235.23 193.74c-2.09082 1.68555 -4.7666 2.69434 -7.6582 2.69434 c-2.89258 0 -5.55078 -1.00879 -7.6416 -2.69434l-235.22 -193.74c-2.0752 -1.71387 -4.73926 -2.75586 -7.63867 -2.75586c-3.73242 0 -7.07031 1.70898 -9.27148 4.38574l-25.5 31c-1.71875 2.07617 -2.7627 4.74414 -2.7627 7.64648 c0 3.72266 1.69824 7.05176 4.3623 9.25391l253.13 208.47c8.29102 6.82227 18.9668 10.9209 30.5312 10.9209s22.1787 -4.09863 30.4688 -10.9209l89.5303 -73.6602v72.6104c0 6.62305 5.37695 12 12 12h56c6.62305 0 12 -5.37695 12 -12v-138.51z" />
		</svg>
	)

	const tabs = [
		{ id: 0, title: <IconHomeWithGradient />, url: "/", tooltipText: "Page d'accueil du site", ref: useRef(null) },
		{ id: 1, title: <>Portfolio</>, url: "/portfolio", tooltipText: "Quelques-unes de mes réalisations", ref: useRef(null) },
		{ id: 2, title: <>Services</>, url: "/services", tooltipText: "Ce que je peux faire pour vous", ref: useRef(null) },
		{ id: 3, title: <>Compétences</>, url: "/skills", tooltipText: "Compétences techniques et soft skills", ref: useRef(null) },
		{ id: 4, title: <>Histoire</>, url: "/about", tooltipText: "L'histoire et les valeurs de mon entreprise", ref: useRef(null) },
		{ id: 5, title: <>Unreferenced tabs</>, hidden: true, url: "/", ref: useRef(null) },
	]

	return (
		<PageLayout>

			<div ref={tabs[0].ref}>

				<ProfilePageHeaderInstagramStyle onTabChange={onTabChange} tabs={tabs} gqlImages={props.data} />

				<div><HomeTab gqlImages={props.data} /></div>
				<div ref={tabs[1].ref}><PortfolioPage gqlImages={props.data} /></div>
				<div ref={tabs[2].ref}><ServicesPage gqlImages={props.data} /></div>
				<div ref={tabs[3].ref}><SkillsPage gqlImages={props.data} /></div>
				<div ref={tabs[4].ref}><AboutMePage gqlImages={props.data} /></div>
				<div ref={tabs[5].ref}>
					{process.env.ENV == 'dev' && <div><TodoPage /></div>}
					<div><ContactSection /></div>
				</div>

			</div>

		</PageLayout>
	)
}

export const pageQuery = graphql`
	query {
		jumboHeaderBackgroundImage: file(relativePath: { eq: "pexels-photo-303383_b-w_blur.png" }) {
			childImageSharp {
				fluid(maxWidth: 800) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
		portfolio_cercle: file(relativePath: { eq: "portfolio_lcdc2_cropped.png" }) {
			childImageSharp {
				fluid(maxWidth: 2000) {
					...GatsbyImageSharpFluid_withWebp_noBase64,
				}
			}
		}
		portfolio_hiro: file(relativePath: { eq: "portfolio_hiro4-fs8.png" }) {
			childImageSharp {
				fluid(maxWidth: 2000) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		portfolio_tlba: file(relativePath: { eq: "portfolio_tlba4-fs8.png" }) {
			childImageSharp {
				fluid(maxWidth: 2000) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		portfolio_airbus: file(relativePath: { eq: "portfolio_airbusworld.png" }) {
			childImageSharp {
				fluid(maxWidth: 2000) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		portfolio_formations: file(relativePath: { eq: "portfolio_formations-fs8.png" }) {
			childImageSharp {
				fluid(maxWidth: 2000) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		vincent_profile_picture: file(relativePath: { eq: "vincent2_lg-removebg.png" }) {
			childImageSharp {
				fluid(maxWidth: 450) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		google_play_badge: file(relativePath: { eq: "google-play-badge.png" }) {
			childImageSharp {
				fluid(maxWidth: 564) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
	}
`;
