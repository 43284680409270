import React, { useEffect, useRef } from "react";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion"
import scrollToElement from '../utils/scrollToElement'
import throttle from '../utils/throttle'

function Underline(props) {
	return (
		<motion.div
			layoutId="underline"
			className="underline"
			initial={false}
			animate={{}}
			transition={{ duration: 1 }}
			style={{ width: '100%', height: 6, borderRadius: 3, position: 'absolute', bottom: -3, background: 'rgba(104,83,255,1)', backgroundImage: 'linear-gradient(125deg, rgba(104,83,255,1) 0%, rgba(212,85,254,1) 100%)' }}
		/>
	)
}

const TabBarItem = React.forwardRef((props, ref) => {
	const { i, title, selected, color, setSelected } = props

	let pageName = props.url.replace(/\W/g, '') //delete all non-letter chars
	if (pageName == "") pageName = 'home-index-id'
	const isSelectedTab = props.id == props.currentTab
	const underlineFullWidth = (props.id == 0) //on tab with icon, make underline full width (else it's too small, almost invisible)

	return (
		<div
			className={'tab-bar-van'}
			style={{
				...{ marginTop: 0, paddingTop: 0, marginBottom: 0, paddingBottom: 0 },
				...{ flex: 'auto', textAlign: 'center' },
			}}
			onClick={() => {
				//history.push(props.url)
				//props.onTabChange(props.id)
				/*props.setCurrentTab(props.id)
				setTimeout(() => {
					//props.onTabChange(props.id)
					//history.push(props.url)
				}, 200)*/
				console.log("ref du tab", ref)
				scrollToElement(ref.current)
			}}
		>
			<motion.div
				key={i}
				id={i}
				layout
				className={isSelectedTab ? 'selected' : ''}
				className='tab-bar-van-inner'
				style={{
					...{ margin: 0, userSelect: 'none', fontSize: isSelectedTab ? '1.1em' : '1em', position: 'relative', cursor: 'pointer' },
					...(underlineFullWidth ? { display: 'block' } : { display: 'inline-block' }),
					...(isSelectedTab &&
						{ background: 'rgba(104,83,255,1)', backgroundImage: 'linear-gradient(125deg, rgba(104,83,255,1) 0%, rgba(212,85,254,1) 100%)', backgroundClip: 'text', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', }
					)
				}}
				animate={{ color: isSelectedTab ? color : "#333" }}
			>
				{isSelectedTab && <Underline color={color} />}
				{title}
			</motion.div>

		</div>
	)
})

function TabBar(props) {

	const [currentTab, setCurrentTab] = React.useState(0)

	const tabBarRef = useRef(null)

	useEffect(() => {
		props.onTabChange(currentTab)
	}, [])

	const getDimensions = (ele) => {
		const { height } = ele.getBoundingClientRect();
		const offsetTop = ele.offsetTop;
		const offsetBottom = offsetTop + height;

		return {
			height,
			offsetTop,
			offsetBottom,
		};
	};

	useEffect(() => {
		const handleScroll = () => {
			let { height: headerHeight } = getDimensions(tabBarRef.current);
			const scrollPosition = window.scrollY + headerHeight;

			let selectedTabId = null
			for (const tab of props.tabs) {
				const element = tab.ref.current;
				if (element) {
					const { offsetBottom, offsetTop } = getDimensions(element);
					if (scrollPosition >= offsetTop - 10) {
						selectedTabId = tab.id
					}
				}
			}
			setCurrentTab(selectedTabId);

		}
		const handleScrollThrottled = throttle(handleScroll, 300, true, true)

		window.addEventListener("scroll", handleScrollThrottled);
		return () => {
			window.removeEventListener("scroll", handleScrollThrottled);
		};
	}, []);

	/* useEffect(() => {
	
		const tabFromUrl = tabs.find(tab => tab.url == location.pathname)
		const selectedTabId = tabFromUrl ? tabFromUrl.id : 0
	
		console.log("LOCATION", location);
		console.log("selectedTabId", selectedTabId);
		setCurrentTab(selectedTabId)
		props.onTabChange(selectedTabId)
	
	}, [location.pathname]) */

	return (
		<div className="mt-5 mb-1" style={{ /*borderTop: '1px solid rgba(0,0,0,0.1)', */borderBottom: '1px solid rgba(0,0,0,0.1)', position: 'sticky', top: -1 /* to fix https://stackoverflow.com/questions/47380910/1px-of-space-using-bootstrap-sticky-navbar-in-chrome */, zIndex: 100, backgroundColor: '#FFF', boxShadow: 'rgba(70,70,70, 0.15) 0px 5px 5px -2px' }}>
			<AnimateSharedLayout>
				<Container>
					<Row>
						<Col className="tabbar-col">
							<div ref={tabBarRef} style={{ display: 'flex', justifyContent: 'space-around', width: '100%', fontFamily: 'Catamaran', textTransform: 'uppercase', fontVariant: 'small-caps', fontWeight: 800, alignItems: 'center', flex: 1 }}>
								{props.tabs.filter(tab => tab.hidden !== true).map((tab, i) => (
									<TabBarItem key={i} {...tab} currentTab={currentTab} setCurrentTab={setCurrentTab} onTabChange={props.onTabChange} />
								))}
							</div>
						</Col>
					</Row>
				</Container>
			</AnimateSharedLayout>
		</div>
	);
}

export default TabBar;
