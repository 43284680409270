import React, { useState, useEffect } from "react";
import { Button, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col, Card, CardImg, CardBody, CardTitle, CardText, Badge } from "reactstrap";
import TabContainer from "./TabContainer";
import { useInView } from "react-intersection-observer";
import { ReactSVG } from 'react-svg'
import SectionSeparator from "components/SectionSeparator";
import SeeMore from "components/SeeMore";

const blackAndWhiteIcons = true

const skillsCategories = [
	{
		category: "Développement Front-End avancé",
		skills: [
			{ type: 'hard', name: "React.js", level: 100, iconPath: 'react.svg', color: '#61DAFB', textColor: 'black' },
			{ type: 'hard', name: "Redux", level: 80, iconPath: 'redux.svg', color: '#764ABC', textColor: 'white' },
			{ type: 'hard', name: "React Router", level: 100, iconPath: 'reactrouter.svg', color: '#CA4245', textColor: 'white' },
			{ type: 'hard', name: "JavaScript Moderne (ES6+)", level: 100, iconPath: 'javascript.svg', color: '#F7DF1E', textColor: 'black' },
			{ type: 'hard', name: "TypeScript", level: 80, iconPath: 'typescript.svg', color: '#007ACC', textColor: 'white' },
			{ type: 'hard', name: "Gatsby", level: 100, iconPath: 'gatsby.svg', color: '#663399', textColor: 'white' },
			{ type: 'hard', name: "Babel", level: 80, iconPath: 'babel.svg', color: '#F9DC3E', textColor: 'black' },
			{ type: 'hard', name: "Webpack", level: 80, iconPath: 'webpack.svg', color: '#8DD6F9', textColor: 'black' },
		]
	},
	{
		category: "Développement Front-End : les basiques",
		skills: [
			{ type: 'hard', name: "HTML5", level: 100, iconPath: 'html5.svg', color: '#E34F26', textColor: 'white' },
			{ type: 'hard', name: "JavaScript", level: 100, iconPath: 'javascript.svg', color: '#F7DF1E', textColor: 'black' },
			{ type: 'hard', name: "jQuery", level: 100, iconPath: 'jquery.svg', color: '#0769AD', textColor: 'white' },
			{ type: 'hard', name: "CSS", level: 100, iconPath: 'css3.svg', color: '#1572B6', textColor: 'white' },
			{ type: 'hard', name: "Sass", level: 100, iconPath: 'sass.svg', color: '#CC6699', textColor: 'white' },
			{ type: 'hard', name: "Bootstrap", level: 100, iconPath: 'bootstrap.svg', color: '#563D7C', textColor: 'white' },
			{ type: 'hard', name: "Tailwind CSS", level: 90, iconPath: 'tailwindcss.svg', color: '#38B2AC', textColor: 'white' },
			{ type: 'hard', name: "Material Design", level: 80, iconPath: 'materialdesign.svg', color: '#757575', textColor: 'white' },
		]
	},
	{
		category: "Développement Back-end",
		skills: [
			{ type: 'hard', name: "Node.js", level: 100, iconPath: 'node-dot-js.svg', color: '#339933', textColor: 'white' },
			{ type: 'hard', name: "Express.js", level: 100, iconPath: 'express.svg', color: '#FDFDFD', textColor: 'black' },
			{ type: 'hard', name: "Java/J2EE", level: 100, iconPath: 'java.svg', color: '#007396', textColor: 'white' },
			{ type: 'hard', name: "PHP", level: 90, iconPath: 'php.svg', color: '#777BB4', textColor: 'white' },
			{ type: 'hard', name: ".NET", level: 80, iconPath: 'dot-net.svg', color: '#5C2D91', textColor: 'white' },
			{ type: 'hard', name: "Création d'API REST", level: 100, iconPath: 'rest-api.svg', color: '#010101', textColor: 'white' },
			{ type: 'hard', name: "GraphQL", level: 80, iconPath: 'graphql.svg', color: '#E10098', textColor: 'white' },
		]
	},
	{
		category: "Développement d'applications mobiles",
		skills: [
			{ type: 'hard', name: "iOS", level: 100, iconPath: 'ios.svg', color: '#000000', textColor: 'white' },
			{ type: 'hard', name: "Android", level: 100, iconPath: 'android.svg', color: '#3DDC84', textColor: 'black' },
			{ type: 'hard', name: "React Native", level: 100, iconPath: 'react-native.svg', color: '#61DAFB', textColor: 'black' },
			{ type: 'hard', name: "Expo", level: 100, iconPath: 'expo.svg', color: '#000020', textColor: 'white' },
		]
	},
	{
		category: "Bases de données",
		skills: [
			{ type: 'hard', name: "MongoDB", level: 100, iconPath: 'mongodb.svg', color: '#47A248', textColor: 'white' },
			{ type: 'hard', name: "MySQL", level: 100, iconPath: 'mysql.svg', color: '#2496ED', textColor: 'white' },
			{ type: 'hard', name: "PostgreSQL", level: 90, iconPath: 'postgresql.svg', color: '#336791', textColor: 'white' },
			{ type: 'hard', name: "Sequelize", level: 90, iconPath: 'sequelize.svg', color: '#52B0E7', textColor: 'white' },
			{ type: 'hard', name: "Oracle", level: 100, iconPath: 'oracle.svg', color: '#F80000', textColor: 'white' },
			{ type: 'hard', name: "SQLite", level: 90, iconPath: 'sqlite.svg', color: '#003B57', textColor: 'white' },
		]
	},
	{
		category: "Infrastructure Back-end",
		skills: [
			{ type: 'hard', name: "Amazon AWS", level: 80, iconPath: 'amazonaws.svg', color: '#232F3E', textColor: 'white' },
			{ type: 'hard', name: "Digital Ocean", level: 100, iconPath: 'digitalocean.svg', color: '#0080FF', textColor: 'white' },
			{ type: 'hard', name: "Linux/Unix", level: 100, iconPath: 'linux.svg', color: '#FCC624', textColor: 'black' },
			{ type: 'hard', name: "Docker", level: 100, iconPath: 'docker.svg', color: '#2496ED', textColor: 'white' },
			{ type: 'hard', name: "Nginx", level: 90, iconPath: 'nginx.svg', color: '#269539', textColor: 'white' },
			{ type: 'hard', name: "Let's Encrypt (SSL/TLS)", level: 90, iconPath: 'letsencrypt.svg', color: '#003A70', textColor: 'white' },
		]
	},
	{
		category: "Intégrations d'outils business",
		skills: [
			{ type: 'hard', name: "Google Analytics", level: 90, iconPath: 'googleanalytics.svg', color: '#E37400', textColor: 'white' },
			{ type: 'hard', name: "Stripe", level: 100, iconPath: 'stripe.svg', color: '#008CDD', textColor: 'white' },
			{ type: 'hard', name: "Zapier", level: 100, iconPath: 'zapier.svg', color: '#FF4A00', textColor: 'white' },
		]
	},
	{
		category: "Mes outils favoris",
		skills: [
			{ type: 'hard', name: "Visual Studio Code", level: 100, iconPath: 'visualstudiocode.svg', color: '#007ACC', textColor: 'white' },
			{ type: 'hard', name: "Git", level: 100, iconPath: 'git.svg', color: '#F05032', textColor: 'white' },
			{ type: 'hard', name: "Github", level: 100, iconPath: 'github.svg', color: '#181717', textColor: 'white' },
			{ type: 'hard', name: "Unix shell", level: 100, iconPath: 'gnubash.svg', color: '#4EAA25', textColor: 'white' },
			{ type: 'hard', name: "Vim", level: 100, iconPath: 'vim.svg', color: '#019733', textColor: 'white' },
			{ type: 'hard', name: "NPM", level: 100, iconPath: 'npm.svg', color: '#CB3837', textColor: 'white' },
			{ type: 'hard', name: "Yarn", level: 100, iconPath: 'yarn.svg', color: '#2C8EBB', textColor: 'white' },
		]
	},
	{
		category: "Divers",
		skills: [
			{ type: 'hard', name: "WordPress et sites vitrines", level: 100, iconPath: 'wordpress.svg', color: '#21759B', textColor: 'white' },
			{ type: 'hard', name: "Extensions pour Google Chrome", level: 100, iconPath: 'googlechrome.svg', color: '#4285F4', textColor: 'white' },
			{ type: 'hard', name: "Temps réel avec Socket.io", level: 100, iconPath: 'socket-dot-io.svg', color: '#010101', textColor: 'white' },
		]
	},
]

const softSkillsCategoriesAll = [
	{
		category: "Analyse et Synthèse",
		skills: [
			{ type: 'soft', name: "Résolution de problèmes complexes", level: 100 },
			{ type: 'soft', name: "Esprit de synthèse", level: 100 },
			{ type: 'soft', name: "Capacité d'absraction", level: 100 },
			{ type: 'soft', name: "Hauteur de vue", level: 100 },
			{ type: 'soft', name: "Rigueur", level: 100 },
		]
	},
	{
		category: "Relationnel équipe et client",
		skills: [
			{ type: 'soft', name: "Capacité à motiver (formé sur le sujet)", level: 100 },
			{ type: 'soft', name: "Capacité à persuader", level: 100 },
			{ type: 'soft', name: "Responsabilisation des équipes", level: 100 },
			{ type: 'soft', name: "Management situationnel", level: 100 },
			{ type: 'soft', name: "Capacité à déléguer", level: 100 },
			{ type: 'soft', name: "Résolution de conflits", level: 100 },
			{ type: 'soft', name: "Esprit d'équipe", level: 100 },
			{ type: 'soft', name: "Excellence du service client", level: 100 },
			{ type: 'soft', name: "Écoute et empathie", level: 100 },
			{ type: 'soft', name: "Communication", level: 100 },
			{ type: 'soft', name: "Gestion d'équipes internationales et interculturelles", level: 100 },
		]
	},
	{
		category: "Pilotage de projet",
		skills: [
			{ type: 'soft', name: "Management opérationnel", level: 100 },
			{ type: 'soft', name: "Suivi, analyse et reporting de projet", level: 100 },
			{ type: 'soft', name: "Management", level: 100 },
			{ type: 'soft', name: "Éthique professionnelle", level: 100 },
			{ type: 'soft', name: "Esprit d'entreprise", level: 100 },
			{ type: 'soft', name: "Prise de décisions", level: 100 },
		]
	},
	{
		category: "Autres",
		skills: [
			{ type: 'soft', name: "Confiance en soi", level: 100 },
			{ type: 'soft', name: "Humilité et remise en question", level: 100 },
			{ type: 'soft', name: "Curiosité intellectuelle", level: 100 },
			{ type: 'soft', name: "Ouverture d'esprit", level: 100 },
			{ type: 'soft', name: "Goût pour le challenge", level: 100 },
			{ type: 'soft', name: "Adaptabilité", level: 100 },
			{ type: 'soft', name: "Créativité", level: 100 },
			{ type: 'soft', name: "Esprit d'initiative", level: 100 },
		]
	},
	{
		category: "Travail sous stress",
		skills: [
			{ type: 'soft', name: "Gestion du temps", level: 100 },
			{ type: 'soft', name: "Gestion du stress", level: 100 },
			{ type: 'soft', name: "Prises de décisions sous stress", level: 100 },
		]
	},
]

const softSkillsCategories = [
	{
		category: "L'Opérationnel",
		skills: [
			{ type: 'soft', name: "Analyse et résolution de problèmes complexes", level: 100 },
			{ type: 'soft', name: "Esprit de synthèse", level: 100 },
			{ type: 'soft', name: "Rigueur", level: 100 },
			{ type: 'soft', name: "Créativité", level: 90 },
			{ type: 'soft', name: "Hauteur de vue", level: 90 },
			{ type: 'soft', name: "Management", level: 90 },
			{ type: 'soft', name: "Travail sous stress", level: 90 },
		]
	},
	{
		category: "Le Relationnel",
		skills: [
			{ type: 'soft', name: "Sens du service", level: 100 },
			{ type: 'soft', name: "Écoute et empathie", level: 100 },
			{ type: 'soft', name: "Éthique professionnelle", level: 100 },
			{ type: 'soft', name: "Communication", level: 100 },
			{ type: 'soft', name: "Esprit d'équipe", level: 100 },
		]
	},
]

function Category(props) {

	const SkillType = props.type

	const [isShowScrollbar, setIsShowScrollbar] = useState(false)
	const [refCategory, categoryIsInView, entry] = useInView({ threshold: 0 });

	useEffect(() => {
		if (categoryIsInView) {
			setIsShowScrollbar(true)
		}
	}, [categoryIsInView]);

	return (
		<div>
			<Row>
				<Col className="ml-auto mr-auto text-center">
					<h2 style={{ textTransform: 'uppercase', fontWeight: 200, margin: '2em auto' }}>{props.category}</h2>
				</Col>
			</Row>
			<div ref={refCategory}>
				<Row>
					{
						props.skills.map((skill, index) => (
							<SkillType key={index} {...skill} isShowScrollbar={isShowScrollbar} indexInCategory={index} />
						))
					}
				</Row>
			</div>
		</div>
	)
}

function TechnoSkill(props) {

	const [width, setWidth] = useState(0)

	useEffect(() => {
		if (props.isShowScrollbar) {
			setWidth(`${props.level}%`)
		}
	}, [props.isShowScrollbar]);

	const delay = `${props.indexInCategory * 0.1}s`

	return (
		<Col md="4">
			<div className='mb-4 mx-1 custom-progress-bar' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minHeight: 50 }}>
				{props.type == 'hard' && <div style={{ width: 50, height: 50, backgroundColor: (blackAndWhiteIcons || props.type == 'soft') ? '#333' : props.color, padding: 10, borderRadius: '25%', marginRight: 10 }}>
					{props.type == 'hard' && <ReactSVG className={`techno-svg-container ${blackAndWhiteIcons ? 'white' : props.textColor}`} src={require(`assets/img/techno_icons/${props.iconPath}`)} style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }} />}
				</div>}
				<div style={{ flex: 1 }}>
					<div style={{ textAlign: 'left', fontSize: '1.3em', marginTop: -5 }}>{props.name}</div>
					<div className="progress skill-bar">
						<div className="progress-bar progress-bar-primary" role="progressbar" style={{ width, transition: `width 0.25s ease ${delay}`, background: (props.type == 'hard' ? 'linear-gradient(90deg, rgba(104,83,255,1) 0%, rgba(212,85,254,1) 100%)' : 'linear-gradient(90deg, #FFA14A 0%, #FFDE4A 100%)') }}>
						</div>
						{/* 
						
						704AFF violet - E640FF rose
						
						30FF38 26FFC0
						2BB330 24B389 */}
					</div>
				</div>
			</div>
		</Col>
	)
}

function SoftSkill(props) {

	const [width, setWidth] = useState(0)

	useEffect(() => {
		if (props.isShowScrollbar) {
			setWidth(`${props.level}%`)
		}
	}, [props.isShowScrollbar]);

	const delay = `${props.indexInCategory * 0.1}s`

	return (
		<Col md="6" lg="4">
			<div className='mb-4 mx-1 custom-progress-bar' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<div style={{ width: 50, height: 50, backgroundColor: '#333', padding: 10, borderRadius: '25%', marginRight: 10 }}>
					LOGO
				</div>
				<div style={{ flex: 1 }}>
					<div style={{ textAlign: 'left', fontSize: '1.3em', marginTop: -5 }}>{props.name}</div>
					<div className="progress skill-bar">
						<div className="progress-bar progress-bar-primary" role="progressbar" style={{ width, transition: `width 0.25s ease ${delay}` }}>
						</div>
					</div>
				</div>
			</div>
		</Col>
	)
}

function SkillsPage(props) {
	return (
		<TabContainer {...props} title="Mes compétences" subtitle="(liste non exhaustive)">

			<SectionSeparator
				title='Compétences'
				subtitle='Mes compétences techniques et soft skills'
			/>

			<div className={`section`}>
				<Container>
					<Row>
						<Col className="ml-auto mr-auto text-center">
							<h2 className="pitch title mb-5">Compétences techniques</h2>
							{/* <h5 className="pitch subtitle portfoliosubtitle text-center" style={{ marginBottom: '3em' }}>Ci-dessous sont listées les principales technologies avec lesquelles j'ai pu travailler.<br />La barre horizontale symbolise mon niveau d'expertise.</h5> */}
						</Col>
					</Row>
					<Row>
						<Col>
							<p className="mb-0" style={{ fontSize: '1.4em' }}>✅ Sites et applications web : {(new Date()).getFullYear() - 2008} ans d'expérience</p>
							<p className="" style={{ fontSize: '1.4em' }}>✅ Applis iOS & Android : 3 ans d'expérience</p>
							<p className="mb-0" style={{ fontSize: '1.4em' }}>🏅 Expert Node.js</p>
							<p className="mb-0" style={{ fontSize: '1.4em' }}>🏅 Expert React.js</p>
							<p className="mb-0" style={{ fontSize: '1.4em' }}>🏅 Expert Java/J2EE</p>
							<p className="" style={{ fontSize: '1.4em' }}>🏅 Expert React Native</p>
							<br />
							<p style={{ fontSize: '1.4em' }}>Ci-dessous un aperçu plus détaillé de mes compétences ainsi que mon niveau d'expertise pour chacune d'entre elles :</p>
						</Col>
					</Row>
					<SeeMore>
						{skillsCategories.map((cat, i) => (
							<Category key={i} {...cat} type={TechnoSkill} />
						))}
					</SeeMore>
				</Container>
			</div>
			<div className={`section`} style={{ background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(248,249,250,1) 100%)' }}>
				<Container>
					<Row>
						<Col className="ml-auto mr-auto text-center">
							<h2 className="pitch title mb-5">Soft skills</h2>
							{/* <h5 className="pitch subtitle portfoliosubtitle" style={{ marginBottom: '3em' }}>Compétences humaines et comportementales résultant de mon parcours mêlant ingénierie logicielle, pilotage de projet et management.</h5> */}
							<h5 className="pitch subtitle portfoliosubtitle text-center" style={{ marginBottom: '3em' }}>Compétences humaines et comportementales</h5>
						</Col>
					</Row>
					{softSkillsCategories.map((cat, i) => (
						<Category key={i} {...cat} type={TechnoSkill} />
					))}
				</Container>
			</div>

		</TabContainer>

	);
}

export default SkillsPage;
