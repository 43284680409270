import React from "react";
/* GATSBY NODE MORE REACT ROUTER import { Link } from "react-router-dom";  */
// reactstrap components
import {
	Collapse,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	NavbarBrand,
	Navbar,
	NavItem,
	NavLink,
	Nav,
	Container,
	UncontrolledTooltip,
} from "reactstrap";

function NavLinkWithTooltip(props) {
	let pageName = props.to.replace(/\W/g, '') //delete all non-letter chars
	if (pageName == "") pageName = 'home-index-id'
	return (
		<>
			<NavLink to={props.to} /*tag={Link} removed because of gatsby*/ id={`navlink-${pageName}`}>
				{props.children}
			</NavLink>
			<UncontrolledTooltip target={`#navlink-${pageName}`}>
				{props.tooltipText}
			</UncontrolledTooltip>
		</>
	)
}

function ExamplesNavbar() {
	const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
	const [collapseOpen, setCollapseOpen] = React.useState(false);
	React.useEffect(() => {
		const updateNavbarColor = () => {
			if (
				document.documentElement.scrollTop > 399 ||
				document.body.scrollTop > 399
			) {
				setNavbarColor("");
			} else if (
				document.documentElement.scrollTop < 400 ||
				document.body.scrollTop < 400
			) {
				setNavbarColor("navbar-transparent");
			}
		};
		window.addEventListener("scroll", updateNavbarColor);
		return function cleanup() {
			window.removeEventListener("scroll", updateNavbarColor);
		};
	});
	return (
		<>
			{collapseOpen ? (
				<div
					id="bodyClick"
					onClick={() => {
						document.documentElement.classList.toggle("nav-open");
						setCollapseOpen(false);
					}}
				/>
			) : null}
			<Navbar className={"fixed-top " + navbarColor} color="info" expand="lg" style={{/* VAN hide scrollbar on scroll */ display: ((navbarColor == "") ? 'none' : 'block') }}>
				<Container>
					<div className="navbar-translate">
						<NavbarBrand
							target="_blank"
							id="navbar-brand"
						>
						</NavbarBrand>
						<button
							className="navbar-toggler navbar-toggler"
							onClick={() => {
								document.documentElement.classList.toggle("nav-open");
								setCollapseOpen(!collapseOpen);
							}}
							aria-expanded={collapseOpen}
							type="button"
						>
							<span className="navbar-toggler-bar top-bar"></span>
							<span className="navbar-toggler-bar middle-bar"></span>
							<span className="navbar-toggler-bar bottom-bar"></span>
						</button>
					</div>
					<Collapse
						className="justify-content-end"
						isOpen={collapseOpen}
						navbar
					>
						<Nav navbar>
							<NavItem>
								<NavLinkWithTooltip to="/" tooltipText="Page d'accueil du site">
									Accueil
								</NavLinkWithTooltip>
							</NavItem>
							<NavItem>
								<NavLinkWithTooltip to="/portfolio" tooltipText="Quelques-unes de mes réalisations">
									Portfolio
								</NavLinkWithTooltip>
							</NavItem>
							<NavItem>
								<NavLinkWithTooltip to="/services" tooltipText="Ce que je peux faire pour vous">
									Mes services
								</NavLinkWithTooltip>
							</NavItem>
							<NavItem>
								<NavLinkWithTooltip to="/skills" tooltipText="Compétences techniques et soft skills">
									Mes compétences
								</NavLinkWithTooltip>
							</NavItem>
							<NavItem>
								<NavLinkWithTooltip to="/about" tooltipText="L'histoire et les valeurs de mon entreprise">
									Histoire & Valeurs
								</NavLinkWithTooltip>
							</NavItem>
							{/* <NavItem>
								<NavLink
									href="https://twitter.com/CreativeTim?ref=creativetim"
									target="_blank"
									id="twitter-tooltip"
								>
									<i className="fab fa-twitter"></i>
									<p className="d-lg-none d-xl-none">Twitter</p>
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									href="https://www.facebook.com/CreativeTim?ref=creativetim"
									target="_blank"
									id="facebook-tooltip"
								>
									<i className="fab fa-linkedin"></i>
									<p className="d-lg-none d-xl-none">LinkedIn</p>
								</NavLink>
								<UncontrolledTooltip target="#facebook-tooltip">
									Like us on Facebook
                				</UncontrolledTooltip>
							</NavItem>
							<NavItem>
								<NavLink
									href="https://www.facebook.com/CreativeTim?ref=creativetim"
									target="_blank"
									id="facebook-tooltip"
								>
									<i className="fab fa-facebook-square"></i>
									<p className="d-lg-none d-xl-none">Facebook</p>
								</NavLink>
								<UncontrolledTooltip target="#facebook-tooltip">
									Like us on Facebook
                				</UncontrolledTooltip>
							</NavItem>
							<NavItem>
								<NavLink
									href="https://www.instagram.com/CreativeTimOfficial?ref=creativetim"
									target="_blank"
									id="instagram-tooltip"
								>
									<i className="fab fa-instagram"></i>
									<p className="d-lg-none d-xl-none">Instagram</p>
								</NavLink>
								<UncontrolledTooltip target="#instagram-tooltip">
									Follow us on Instagram
                				</UncontrolledTooltip>
							</NavItem> */}
						</Nav>
					</Collapse>
				</Container>
			</Navbar>
		</>
	);
}

export default ExamplesNavbar;
