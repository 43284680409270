import React from 'react'
import { Button } from "reactstrap";

function GradientButton(props) {
	const initialProps = {
		className: 'btn',
		color: 'primary',
		size: 'lg',
		style: { background: 'linear-gradient(125deg, rgba(104,83,255,1) 0%, rgba(212,85,254,1) 100%)' }
	}
	const mergedProps = { ...initialProps, ...props }
	return (
		<Button {...mergedProps} style={{ ...initialProps.style, ...props.style }}>
			{mergedProps.children}
		</Button>
	)
}

export default GradientButton
