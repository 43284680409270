import React, { useState, useEffect } from "react";
import { useSprings, animated, interpolate } from "react-spring/hooks";
import { ReactSVG } from "react-svg";
import { useGesture } from "react-with-gesture";
import GradientButton from "./GradientButton";
import scrollToElement from "../utils/scrollToElement"
import { useInView } from "react-intersection-observer";

/*

Code récupéré depuis https://github.com/GavBaros/react-tinder-cards

Mais en fait le vrai auteur est https://github.com/pmndrs/react-use-gesture avec ce sandbox : https://codesandbox.io/embed/j0y0vpz59

*/

// These two are just helpers, they curate spring data, values that are later being interpolated into css
const to = i => ({ x: 0, y: i * -4, scale: 1, rot: -10 + Math.random() * 20, delay: i * 100 })
const from = i => ({ x: 0, rot: 0, scale: 1.5, y: -1000 })

// This is being used down there in the view, it interpolates rotation and scale into a css transform
const trans = (r, s) => `perspective(1500px) rotateX(30deg) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`;

const Card = ({ i, x, y, rot, scale, trans, bind, data }) => {
	const { when, title, image, body } = data

	return (
		<animated.div
			key={i}
			style={{
				transform: interpolate([x, y], (x, y) => `translate3d(${x}px,${y}px,0)`)
			}}
		>
			<animated.div
				{...bind(i)}
				style={{
					transform: interpolate([rot, scale], trans)
				}}
				className="card-of-deck"
			>
				<div className="card-content">
					{/* <div className='cardimg' style={{}}>{image}</div> */}
					<h4 className='mx-0 my-0 px-0 py-0' style={{ fontFamily: 'Catamaran', fontWeight: 800 }}>{image} {title}</h4>
					{when && <p style={{ color: '#888' }}>{when}</p>}
					<div style={{ fontSize: '0.85em' }}>
						{body}
					</div>
				</div>
			</animated.div>
		</animated.div>
	);
};

function Deck({ cardsData }) {
	const [gone] = useState(() => new Set()) // The set flags all the cards that are flicked out

	const [isShowInstructions, setIsShowInstructions] = useState(true)

	const [props, set] = useSprings(cardsData.length, i => ({ ...to(i), from: from(i) })) // Create a bunch of springs using the helpers above

	const resetDeck = (() => gone.clear() || set(i => to(i)))

	// Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
	const bind = useGesture(({ args: [index], down, delta: [xDelta], distance, direction: [xDir], velocity }) => {
		const trigger = velocity > 0.2 // If you flick hard enough it should trigger the card to fly out

		const dir = xDir < 0 ? -1 : 1 // Direction should either point left or right

		if (!down && trigger) gone.add(index) // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out

		set(i => {
			if (index !== i) return // We're only interested in changing spring-data for the current spring
			const isGone = gone.has(index)

			const x = isGone ? (200 + window.innerWidth) * dir : down ? xDelta : 0 // When a card is gone it flys out left or right, otherwise goes back to zero

			const rot = xDelta / 100 + (isGone ? dir * 10 * velocity : 0) // How much the card tilts, flicking it harder makes it rotate faster

			const scale = down ? 1.1 : 1 // Active cards lift up a bit
			return { x, rot, scale, delay: undefined, config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 } };
		});

		if (!down && gone.size === cardsData.length) {
			//setTimeout(resetDeck, 600)
		}

		if (gone.size >= 2) setIsShowInstructions(false)
	})


	// Intersection observer to disable click on cards before stopping there
	const [refHideOverlayThreshold, deckIsInView, entry] = useInView({ threshold: 0 });
	const [showOverlay, setShowOverlay] = useState(true)
	useEffect(() => {
		if (deckIsInView) {
			setShowOverlay(false)
		}
	}, [deckIsInView]);


	// Now we're just mapping the animated values to our view, that's it. Btw, this component only renders once. :-)
	return (
		<>
			<div className="deck-container">
				<div className="behind-cards" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
					{/* 					<div className="subtitle pitch">Vous avez un projet ?<br /></div>
					<GradientButton style={{ margin: '10px 0px 30px', width: 180, padding: '15px 20px' }} onClick={() => console.log("click contact")}>
						Contactez-moi 👍
				</GradientButton> */}
					<GradientButton style={{ margin: 0, width: 250, padding: '15px 10px', backgroundImage: null, background: '#FFF', border: '1px solid #555', color: '#555' }} onClick={resetDeck}>
						<i className="fas fa-redo mr-2"></i> Replacer les cartes
					</GradientButton>

					<GradientButton className='mt-4' style={{ margin: 0, width: 250, padding: '15px 10px', backgroundImage: null, background: '#FFF', border: '1px solid #555', color: '#555' }} onClick={() => scrollToElement('#contactme-anchor', false)}>
						<i className="fas fa-comment mr-2"></i> Parlez-moi de votre projet
					</GradientButton>

					{/* <GradientButton style={{ margin: 0, }} onClick={resetDeck}>
						Replacer les cartes
				</GradientButton> */}
				</div>
				{props.map(({ x, y, rot, scale }, i) => (
					<Card
						key={i}
						i={i}
						x={x}
						y={y}
						rot={rot}
						scale={scale}
						trans={trans}
						data={cardsData[i]}
						bind={bind}
					/>
				))}


				{/* Thin (5px height) full width rectangle at 70% of deck's height that triggers hiding the overlay */}
				<div ref={refHideOverlayThreshold} style={{ position: 'absolute', top: '70%', left: 0, right: 0, height: 5 }}></div>

				{/* the overlay on the deck of cards */}
				{showOverlay && (
					<div style={{ position: 'absolute', top: 0, left: 0, right: 0, height: '100%' }}></div>
				)}
			</div>
			<div className="mb-5 px-3 text-center" style={{ opacity: isShowInstructions ? 1 : 0, transition: 'opacity 3s' }}>

				<div id='swipeiconsvg' className='mb-3 text-center d-inline-block' style={{ width: 80 }}>
					<ReactSVG src={require('assets/img/swipe.svg')} />
				</div>
				<div style={{ fontSize: '1.8em' }}>
					Balayez les cartes vers la gauche ou la droite
				</div>
			</div>
		</>
	)

}

export default Deck;
