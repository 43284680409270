import React from "react";
import { Button, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col } from "reactstrap";
import TabContainer from "./TabContainer";
import SectionSeparator from "components/SectionSeparator";
import Deck from "components/Deck";

let isOdd = true

function AboutItem(props) {
	isOdd = !isOdd

	return (

		<div className={`section ${isOdd ? '' : 'bg-light'}`}>
			<Container>
				<Row className="mb-5">
					<Col className="ml-auto mr-auto text-center">
						<h2 className="pitch title mb-5">{props.title}</h2>
						<h6 className="emoji">{props.image}</h6>
						<h5 className="pitch subtitle pb-3" style={{ marginBottom: '3em', whiteSpace: 'pre-wrap' }}>
							{props.when}
						</h5>
						<div align="left">
							{props.children}
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	)

	return (
		<div className={`section section-team text-center ${isOdd ? '' : 'bg-light'}`}>
			<Container>
				<h2 className="title">{props.when}</h2>

				<h6 className="emoji">{props.image}</h6>

				<h2 className="subtitle">{props.title}</h2>

				<div align="left">
					{props.children}
				</div>

			</Container>

		</div>

	)
}

function AboutMePage(props) {
	return (
		<TabContainer {...props} title="Mon histoire, mes valeurs" subtitle="Le parcours qui a fait de moi ce que je suis aujourd'hui">

			<SectionSeparator
				title='À propos de moi'
				subtitle='Mon histoire et mes valeurs'
			/>

			<Deck cardsData={[
				{
					when: "2000-2001",
					image: "💡",
					title: "Découverte de la programmation",
					body: <>
						<p>Âgé de 15 ans, je découvre la programmation et c'est une révélation. Créer mes propres interfaces graphiques me permet de donner libre cours à ma créativité et d'inventer des choses.</p>
						<p>Mon premier logiciel ?</p>
						<p>Une interface de caisse enregistreuse pour ma petite sœur, alors âgée de 11 ans, dont la passion était de "jouer à la vendeuse" ! (client exigeant 😉)</p>
					</>
				},
				{
					when: "2003 ⇒ 2008",
					image: "🎓",
					title: "Cursus en École d'Ingénieur",
					body: <>
						<p>J'obtiens en 2008 le diplôme d'Ingénieur en Informatique de l'INSA de Toulouse.</p>
						<p>Durant les 5 années de cursus, j'ai découvert les technologies, les méthodologies, les concepts et le savoir-faire qui deviendront les fondations de ma carrière.</p>
					</>
				},
				{
					when: "2008 ⇒ 2013",
					image: "👨‍💻",
					title: "Ingénieur logiciel pour Airbus",
					body: <>
						<p>Pendant 5 ans, je travaille comme ingénieur logiciel full-stack pour Airbus (via Sopra Steria) sur Toulouse.</p>
						<p>Créer du logiciel pour un grand groupe implique de nombreuses spécificités (process, qualité, outils, inertie, etc). Ce contexte métier me permettra d'acquérir une grande rigueur et des méthodes de programmation, de test et d'automatisation robustes pour atteindre les exigences de qualité propres à l'industrie aéronautique, afin de produire des logiciels avec des taux de bugs proches de zéro.</p>
					</>
				},
				{
					when: "2013 ⇒ 2018",
					image: "👨‍💼",
					title: "Chef de projet & Manager",
					body: <>
						<p>Je poursuis pendant les 5 années suivantes une carrière de chef de projet et manager très épanouissante.</p>
						<p>Sur les 2 dernières années, je gérais des équipes interculturelles (France et Inde) de 20 à 25 ingénieurs. Une aventure humaine incroyable.</p>
						<p>Pourtant, lorsque j'arrivais à me dégager du temps, je me surprenais à améliorer le code du projet (ce qui n'était plus mon rôle)... La créativité de l'ingénierie logicielle commençait peut-être à me manquer...</p>
					</>
				},
				{
					when: "2018",
					image: "🖤",
					title: "Ma vie bascule",
					body: <>
						<p>En l'espace de 46 jours, je vis les 2 événements les plus marquants de ma vie : Début février, je perds mon père du jour au lendemain. 46 jours plus tard, je deviens papa pour la première fois.</p>
						<p>Je réalise alors pleinement à quel point la vie est aussi fragile que précieuse. Mes priorités deviennent évidentes. Fini de reporter mes rêves à plus tard. Quelle que soit la chose que l'on souhaite faire au fond de soi, il n'y aura jamais de bon moment, de moment parfait, pour se lancer. Le meilleur moment c'est tout simplement maintenant.</p>
					</>
				},
				{
					when: "2018",
					image: "🔥",
					title: "Un nouveau départ",
					body: <>
						<p>Sur le plan professionnel, tout ceci se traduit par une envie de revenir à ma passion, l'ingénierie : concevoir, créer, construire. Je suis un ingénieur, j'aime créer et inventer. Voilà ce qui me fait vibrer. Et voilà ce que je veux me remettre à faire. Immédiatement.</p>
						<p>À une différence près : je veux dorénavant travailler à ma façon, avec mes valeurs : rigueur, éthique, extrême fiabilité des livrables, excellence du service client et recherche permanente du résultat optimal.</p>
						<p>Je décide donc de créer mon entreprise.</p>
					</>
				},
				{
					when: "2019",
					image: "🎉",
					title: "Création de VA Solutions",
					body: <>
						<p>Ma société créée, je mets mon savoir-faire au service de VOS projets.</p>
						<p>Ma double expérience (Ingénieur pour de grands groupes + Créateur de startups et entrepreneur) me permet d'être très efficace dans tous types d'entreprises et de contextes, du solo-entrepreneur qui veut donner vie à son idée, au groupe multinational qui veut optimiser ses process via des outils digitaux de pointe.</p>
						<p>(voir 2 cartes suivantes)</p>

					</>
				},
				{
					when: " ",
					image: "🏢",
					title: "L'expérience des grands groupes",
					body: <>
						<p>Mon expérience dans les grands groupes comme Airbus me permet de m'adapter très facilement aux contextes de production propres aux grosses entreprises :<br />
						— Processus qualité<br />
						— Processus de production<br />
						— Processus de livraison<br />
						— Processus de déploiement<br />
						— Intégration continue<br />
						— Méthodes Agiles (Scrum, Kanban, SAFe,...)<br />
						— etc...</p>
					</>
				},
				{
					when: " ",
					image: "🚀",
					title: "L'expérience des Startups",
					body: <>
						<p>Mon expérience en tant que créateur de startups de type SaaS me permet également de m'adapter très facilement aux contextes de solo-entreprises et startups :<br />
						— Intégration d'outils marketing<br />
						— Optimisation des conversions<br />
						— A/B testing<br />
						— Gestion de paiements et d'abonnements (modèle SaaS)<br />
						— Growth hacking<br />
						— etc...</p>
					</>
				},
			].reverse()} />

		</TabContainer>

	);
}

export default AboutMePage;
