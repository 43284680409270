import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

function SectionSeparator(props) {

	const [refTitle, titleIsInView, entry] = useInView({ threshold: 0 });

	const [nativeCssTransitionLineOpacity, setNativeCssTransitionLineOpacity] = useState(0)
	const [nativeCssTransitionLineScaleX, setNativeCssTransitionLineScaleX] = useState(0)
	const [windowInnerHeight, setWindowInnerHeight] = useState(500)

	const backgroundColorOfNextSection = props.backgroundColorOfNextSection || 'white'

	useEffect(() => {
		if (titleIsInView) {
			setNativeCssTransitionLineOpacity(1)
			setNativeCssTransitionLineScaleX(1)
		}
	}, [titleIsInView]);

	useEffect(() => {
		setWindowInnerHeight(typeof window === `undefined` ? 500 : window.innerHeight)
	}, []);

	return (
		<div className={`section`} style={{ padding: 0, position: 'relative', minHeight: windowInnerHeight, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: '4.375vw', marginBottom: '4.375vw' /* 50vw*tan5°=4.375vw */ }}>

			<div style={{ position: 'absolute', bottom: '-4.375vw', left: 0, right: 0, height: windowInnerHeight / 2, zIndex: 1, backgroundColor: backgroundColorOfNextSection }}></div>

			<div style={{ position: 'absolute', top: 0, left: 0, right: 0, minHeight: windowInnerHeight, transform: 'skewY(-5deg)', background: 'rgba(104,83,255,1)', backgroundImage: 'linear-gradient(125deg, rgba(104,83,255,1) 0%, rgba(212,85,254,1) 100%)', zIndex: 2 }}>

			</div>

			<div style={{ color: '#FFF', zIndex: 2, textAlign: 'center' }}>
				<h2 style={{ display: 'inline-block', position: 'relative', margin: 0, padding: 0 }} className="pitch title mb-5">
					<div style={{ width: '100%', height: 8, borderRadius: 4, position: 'absolute', bottom: -15, backgroundColor: '#FFF', opacity: nativeCssTransitionLineOpacity, transition: 'opacity 1.5s cubic-bezier(0.08,0.82,0.17,1), transform 1.5s cubic-bezier(0.08,0.82,0.17,1)', transform: `scaleX(${nativeCssTransitionLineScaleX})`, transformOrigin: '50% 50%' }} >
					</div>
					<span ref={refTitle} style={{ color: '#FFF' }}>
						{props.title}
					</span>
				</h2>
				<h2 style={{ color: '#EEE', marginTop: 0, paddingTop: 0, marginBottom: 0, paddingBottom: 0 }} className="pitch subtitle mt-3">
					— {props.subtitle} —
				</h2>
			</div>

		</div>
	)
}

export default SectionSeparator
