import Footer from "components/Footers/CustomFooter.js";
import Navbar from "components/Navbars/Navbar.js";
import React, { useRef, useState } from "react";
import "../assets/css/bootstrap.min.css";
import "../assets/scss/now-ui-kit.scss?v=1.4.0";
import SEO from "./SEO";


const BootstrapIndicator = (props) => {
	return (
		<>
			<div style={{ position: 'fixed', zIndex: 10000, backgroundColor: 'orange', top: '0px', left: '0px' }} className='d-block d-sm-none d-md-none d-lg-none d-xl-none'>xs</div>
			<div style={{ position: 'fixed', zIndex: 10000, backgroundColor: 'orange', top: '0px', left: '0px' }} className='d-none d-sm-block d-md-none d-lg-none d-xl-none'>sm</div>
			<div style={{ position: 'fixed', zIndex: 10000, backgroundColor: 'orange', top: '0px', left: '0px' }} className='d-none d-sm-none d-md-block d-lg-none d-xl-none'>md</div>
			<div style={{ position: 'fixed', zIndex: 10000, backgroundColor: 'orange', top: '0px', left: '0px' }} className='d-none d-sm-none d-md-none d-lg-block d-xl-none'>lg</div>
			<div style={{ position: 'fixed', zIndex: 10000, backgroundColor: 'orange', top: '0px', left: '0px' }} className='d-none d-sm-none d-md-none d-lg-none d-xl-block'>xl</div>
		</>
	);
}

function PageLayout(props) {

	return (
		<>

			<SEO />

			{/* <Navbar /> */}

			{process.env.ENV == 'dev' && <BootstrapIndicator />}

			{props.children}

			{/* <Footer /> */}

		</>

	);
}

export default PageLayout;
