import React from "react";

function TabContainer(props) {
	return (
		<div className='pb-3' {...props}>
			{props.children}
		</div>
	);
}

export default TabContainer;
