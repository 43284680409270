const scrollToElement = (ele, isSmooth = true) => {
	if (typeof ele === 'string') {
		//it means the ele is a selector, not an actual element, so we retrieve it
		ele = document.querySelector(ele)
	}
	ele.scrollIntoView({
		behavior: isSmooth ? "smooth" : "instant",
		block: "start",
	});
};

export default scrollToElement