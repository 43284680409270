import React, { useState, useEffect } from "react";
import GradientButton from "./GradientButton";
import { motion, useAnimation } from "framer-motion"

function SeeMore(props) {

	const [isExpanded, setIsExpanded] = useState(false)

	const initialMaxHeight = (() => {
		if (typeof window === 'undefined') return 1500
		if (window.innerWidth <= 575) return 970	//xs [  0 - 575]
		if (window.innerWidth <= 767) return 1000	//sm [576 - 767]
		if (window.innerWidth <= 991) return 1580	//md [768 - 991]
		if (window.innerWidth <= 1199) return 1500	//md [992 - 1199]
		else return 1500 							//xl [1200-]
	})()

	const [nativeCssTransitionMaxHeight, setNativeCssTransitionMaxHeight] = useState(initialMaxHeight) //TODO ne réagit pas si la largeur change (ex: on tourne un smartphone en paysage) car le composant n'est pas re-rendu lorsque la largeur de la fenêtre change. Pas grave pour le moment

	useEffect(() => {
		if (isExpanded) {
			setNativeCssTransitionMaxHeight(20000)
		}
	}, [isExpanded]);


	return (
		<div
			style={{ position: 'relative', padding: 0, margin: 0, maxHeight: nativeCssTransitionMaxHeight, overflow: 'hidden', transition: 'max-height 5s' }}
		>

			{props.children}

			{!isExpanded && (
				<div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
					<div style={{ margin: 0, padding: 0, height: '150px', background: '#FFF', background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)' }}>
					</div>
					<div style={{ margin: 0, padding: '10px 0px 30px 0px', backgroundColor: '#FFF', textAlign: 'center' }}>
						<GradientButton
							style={{ margin: 0 }}
							onClick={() => setIsExpanded(true)}
						>
							Voir plus
					</GradientButton>
					</div>
				</div>
			)}

		</div>
	)
}

export default SeeMore
