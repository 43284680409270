import React from 'react'
import { ReactSVG } from 'react-svg'

function ArrowLink(props) {

	let OuterElement
	if (props.href) {
		OuterElement = (proppps) => <a {...props} href={props.href} target="_blank" rel="noopener noreferrer" className="arrow-link">{proppps.children}</a>
	} else {
		OuterElement = (proppps) => <span {...props} className="arrow-link" onClick={props.onClick}>{proppps.children}</span>
	}

	return (
		<OuterElement>
			{props.children}
			<svg style={{}} height="8" viewBox="0 0 27 8" width="27" xmlns="http://www.w3.org/2000/svg"><path clipRule="evenodd" d="M23.172.464l3.182 3.182a.5.5 0 010 .708l-3.182 3.182a.5.5 0 11-.707-.708L24.793 4.5H0v-1h24.793l-2.328-2.328a.5.5 0 11.707-.708z"></path></svg>
		</OuterElement>
	)
}

export default ArrowLink
