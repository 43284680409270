import React from "react";
import { Col, Container, Row, Button } from "reactstrap";

function DarkFooter() {
	return (
		<footer className="footer" data-background-color="black">
			<Container>
				<Row>
					<Col md="4" style={{ textAlign: 'left' }}>
						<nav>
							<ul>
								<li><a href="/">Accueil</a></li>
								<li><a href="/contact">FAQ</a></li>
								<li>
									<Button
										// className="btn btn-me-contacter animate__animated animate__pulse wow"
										// data-wow-delay="1s"
										// data-wow-duration="2s"
										color="primary"
										size="sm"
										style={{ color: '#2C2C2C', background: '#EEE' }}
										onClick={() => {
											const newShowValue = !showContactButtons
											const node = document.querySelector('#contact-buttons')
											if (newShowValue === true) {
												// node.classList.remove('d-none')
												node.classList.remove('van-height-0')
												node.classList.add('animate__animated', 'animate__backInDown')
												node.addEventListener('animationend', () => {
													// node.classList.add('d-block')
													node.classList.remove('animate__animated', 'animate__backInDown');
												}, { once: true });
											} else {
												// node.classList.remove('d-block')
												node.classList.add('animate__animated', 'animate__backOutUp')
												node.classList.add('van-height-0')
												node.addEventListener('animationend', () => {
													// node.classList.add('d-none')
													node.classList.remove('animate__animated', 'animate__backOutUp');
												}, { once: true });
											}
											setShowContactButtons(newShowValue)
										}}>
										Me contacter
              						</Button>
								</li>
							</ul>
						</nav>
					</Col>
					<Col md="4" style={{ textAlign: 'center' }}>
						<div className="copyright" id="copyright">
							© {new Date().getFullYear()} VA Solutions
        				</div>
					</Col>
					<Col md="4" style={{ textAlign: 'right' }}>
						<div className="copyright" id="copyright">
							<div>VA Solutions</div>
							<div>SIRET 85048603600013</div>
							<div>13 Rue Sainte Ursule</div>
							<div>31000 Toulouse</div>
							Vincent Andrieu
        				</div>
					</Col>
				</Row>
			</Container>
		</footer>
	);
}

export default DarkFooter;
