import React from "react";
import { Button, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col } from "reactstrap";
import ArrowLink from "components/ArrowLink";
import TabContainer from "./TabContainer";
import scrollToElement from '../utils/scrollToElement'

function HomeTab(props) {
	return (
		<TabContainer {...props} title="Accueil">

			<Container>
				<Row>
					<Col>
						<div className='py-5' style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', justifyContent: 'center', fontSize: '1.3em' }}>


							<p className='mb-4'>Bienvenue ! 🙋‍♂️</p>

							<p className='mb-4'>Ingénieur avec <span className="strong-home-text">{(new Date()).getFullYear() - 2008} ans d'expérience</span> professionnelle dans le développement logiciel dont 10 dans l'aéronautique, je suis en mesure de créer pour vous le logiciel dont vous avez besoin, qu'il s'agisse d'une <span className="strong-home-text">application web</span>, d'une <span className="strong-home-text">application mobile iOS ou Android</span>, d'un logiciel de bureau, ou d'une infrastructure métier back-end.</p>
							<p className='mb-4'>J'interviens également en tant que consultant, expert ou tech lead sur des projets ayant besoin de renforts expérimentés.</p>

							{/* <p className='mb-4'>Mon objectif est de vous permettre d'<span className="strong-home-text">atteindre vos objectifs</span> et de <span className="strong-home-text">concrétiser vos projets</span> : je suis au service de <span className="strong-home-text">vos besoins</span> et des <span className="strong-home-text">enjeux de votre projet</span>.</p> */}

							<p className='mb-4'>Ma force est de toujours livrer <span className="strong-home-text">dans les délais</span> un produit avec un niveau de <span className="strong-home-text">qualité, de robustesse et de performances</span> dignes des exigences aéronautiques qui ont construit mon savoir-faire.</p>

							<p className='mb-4'>Vous trouverez ci-dessous des exemples de réalisations, les services que je peux vous proposer, mes compétences, ainsi que l'histoire et les valeurs de mon entreprise.</p>

							<p className='mb-4'>
								Vous avez un projet en tête ?
								<br />
								<ArrowLink onClick={() => scrollToElement('#contactme-anchor', false)}>Contactez-moi</ArrowLink>
							</p>

						</div>
					</Col>
				</Row>
			</Container>

		</TabContainer>

	);
}

export default HomeTab;
