import React, { useEffect, useState } from "react";
import Img from "gatsby-image";
import { useInView } from "react-intersection-observer";
import { Badge, Button, Card, CardBody, CardImg, CardText, CardTitle, Col, Container, Row } from "reactstrap";
import GradientButton from "components/GradientButton";
import SectionSeparator from "components/SectionSeparator";
import TabContainer from "./TabContainer";
import ArrowLink from "components/ArrowLink";

function MyBadge(props) {
	return (
		<Badge style={{ borderColor: '#AAA', color: '#AAA', backgroundColor: 'transparent', fontSize: '0.75em', paddingTop: 5 }} className="mr-1" color="default">{props.children}</Badge>
		// <div style={{ borderWidth: 1, borderColor: '#CCC', color: '#CCC', fontSize: '0.6em', padding: '4px 8px', textTransform: 'uppercase', lineHeight: 12, borderRadius:'0.875rem', display:'inline-block', fontWeight:700,textAlign:'center', whiteSpace:'nowrap',  }} className="mr-1" color="default">
		// 	{props.children}
		// </div>
	)
}


function RatingStars(props) {

	function RatingStar(props) {
		let percentageStarFilled
		if (props.value < props.start) {
			percentageStarFilled = 0
		} else if (props.value > props.end) {
			percentageStarFilled = 100
		} else {
			percentageStarFilled = Math.ceil(100 * (props.value - props.start) / (props.end - props.start))
		}
		return (
			<div style={{ position: 'relative', display: 'inline-block', margin: 0, padding: 0, lineHeight: 1 }}>
				<span style={{ zIndex: 1, opacity: 0.33 }} className="fa fa-star"></span>
				<span style={{ zIndex: 2, position: 'absolute', top: 0, left: 0, width: `${percentageStarFilled}%`, overflow: 'hidden' }} className="fa fa-star checked"></span>
			</div>
		)
	}

	const ratingString = ('' + props.value).replace('.', ',')

	return (
		<div {...props}>
			<RatingStar start={0} end={1} value={props.value} />
			<RatingStar start={1} end={2} value={props.value} />
			<RatingStar start={2} end={3} value={props.value} />
			<RatingStar start={3} end={4} value={props.value} />
			<RatingStar start={4} end={5} value={props.value} />
			<span style={{ marginLeft: 5 }}>{ratingString}</span>
		</div>
	)
}

function PortfolioItemSection(props) {

	const isOdd = props.index % 2 == 0

	const [refColImage, imageIsInView, imageEntry] = useInView({ threshold: 0.1 });
	const [refColText, textIsInView, textEntry] = useInView({ threshold: 0.1 });

	const [nativeCssTransitionImageOpacity, setNativeCssTransitionImageOpacity] = useState(0)
	const [nativeCssTransitionImageScale, setNativeCssTransitionImageScale] = useState(0.7)
	const [nativeCssTransitionTextOpacity, setNativeCssTransitionTextOpacity] = useState(0)
	const [nativeCssTransitionTextScale, setNativeCssTransitionTextScale] = useState(0.7)

	useEffect(() => {
		if (imageIsInView) {
			setNativeCssTransitionImageOpacity(1)
			setNativeCssTransitionImageScale(1)
		}
	}, [imageIsInView]);

	useEffect(() => {
		if (textIsInView) {
			setNativeCssTransitionTextOpacity(1)
			setNativeCssTransitionTextScale(1)
		}
	}, [textIsInView]);

	return (
		<div className={`section ${isOdd ? '' : 'bg-light'}`}>
			<Container>
				<Row>
					<Col className="ml-auto mr-auto text-center">
						<h2 style={{ fontSize: '2.5em' }} className="pitch title pb-3 mb-5">{props.title}</h2>
					</Col>
				</Row>
				<Row>
					<Col md="6" className={`${isOdd ? 'order-md-1' : 'order-md-2'}`}>
						<div ref={refColImage}>
							{/* <motion.div animate={animColImage} initial={{ x: 100 * (isOdd ? -1 : 1), opacity: 0 }}> */}
							<div style={{ opacity: nativeCssTransitionImageOpacity, transition: 'opacity 0.5s, transform 0.5s', transform: `scale(${nativeCssTransitionImageScale})`, transformOrigin: '50% 0%' }}>
								{props.showMacBookFrame
									?
									<div className="" style={{ backgroundColor: '#263238', paddingBottom: 10, borderRadius: 10 }}>
										{/* <div className="card-img-top" style={{ backgroundColor: '#575A5D', paddingBottom: 10 }}> */}
										<div style={{ paddingLeft: 9, paddingTop: 2, paddingBottom: 2 }}>
											<svg xmlns="http://www.w3.org/2000/svg" width="30" height="14" viewBox="0 0 54 14"><g fill="none" fill-rule="evenodd" transform="translate(1 1)"><circle cx="6" cy="6" r="6" fill="#FF5F56" stroke="#E0443E" stroke-width=".5"></circle><circle cx="26" cy="6" r="6" fill="#FFBD2E" stroke="#DEA123" stroke-width=".5"></circle><circle cx="46" cy="6" r="6" fill="#27C93F" stroke="#1AAB29" stroke-width=".5"></circle></g></svg>
										</div>
										<div style={{ marginLeft: 10, marginRight: 10 }}>
											<img style={{ borderBottomLeftRadius: 'calc(.25rem - 1px)', borderBottomRightRadius: 'calc(.25rem - 1px)' }} src={props.img} className="card-img-top"></img>
										</div>
									</div>
									:
									<Img loading='eager'/*désactive le lazy loading */ fluid={props.gatsbyImg} alt={props.alt} />
								}
							</div>
						</div>
					</Col>
					<Col md="6" className={`mt-5 mt-md-0 ${isOdd ? 'order-md-2' : 'order-md-1'}`} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
						<div ref={refColText}>
							<div style={{ opacity: nativeCssTransitionTextOpacity, transition: 'opacity 0.5s, transform 0.5s', transform: `scale(${nativeCssTransitionTextScale})`, transformOrigin: '50% 0%' }}>

								<h5 className="pitch subtitle portfoliosubtitle" style={{ marginBottom: '3em', whiteSpace: 'pre-wrap' /*permet d'afficher des sauts de ligne pour les \n car <br> ne marche pas ici*/ }}>{props.shortDesc.map(phrase => (phrase + "\n"))}</h5>

								{props.myJob &&
									<div style={{ marginBottom: '2rem' }}>
										<p>
											<span style={{ fontWeight: 700 }}>Mon rôle : </span>
											<span style={{ whiteSpace: 'pre-wrap' }}>{props.myJob}</span>
										</p>
									</div>
								}
								{props.myJobFormations &&
									<div style={{ marginBottom: '2rem' }}>
										{props.myJobFormations.map(category => (
											<>
												<p>
													✅ {category.title}
													{category.details.map(detail => (
														<div className="ml-3 my-0">
															<span>👉 </span>
															{detail.url ?
																<ArrowLink href={detail.url}>{detail.title}</ArrowLink>
																:
																<span>{detail.title}</span>
															}
															<br />
														</div>
													))}
												</p>
											</>
										))}
									</div>
								}
								{/* <div>
						{props.technos.map(t => <Badge style={{ borderWidth: 0, borderColor: '#CCC', backgroundColor: '#CCC', fontSize: '0.6em' }} className="mr-1" color="default">{t}</Badge>)}
					</div> */}
								<div style={{ marginBottom: '2rem' }}>
									{props.technos && props.technos.map((t, i) => <MyBadge key={i}>{t}</MyBadge>)}
								</div>
								<div className='text-center text-md-left'>
									{props.links.map((link, i) => {
										if (link.type == 'ios') {
											return (
												<div key={i} style={{ display: 'inline-flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
													<a title="Télécharger dans l'app store" target="_blank" href="https://apps.apple.com/us/app/le-cercle-du-cashflow/id1460008725?l=fr&ls=1">
														<img alt="Télécharger dans l'app store" style={{ height: 40, width: 126.5, margin: '10px 3px 4px 3px' }} src={require('assets/img/app-store-badge.svg')} />
													</a>
													<RatingStars style={{ display: 'inline-block' }} value={4.7} />
													<span>6.500 utilisateurs</span>
												</div>
											)
										} else if (link.type == 'android') {
											return (
												<div key={i} style={{ display: 'inline-flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
													<a title="Télécharger dans le play store" target="_blank" href="https://play.google.com/store/apps/details?id=com.vasolutions.cashflowmastermind">
														<img alt="Télécharger dans le play store" style={{ height: 40, width: 134.28, margin: '10px 3px 4px 3px' }} src={require('assets/img/google-play-badge.png')} />
													</a>
													<RatingStars style={{ display: 'inline-block' }} value={4.7} />
													<span>6.000 utilisateurs</span>
												</div>


											)
										} else {
											return <GradientButton key={i} href={link.url} title={link.text}>{link.text}</GradientButton>
										}
									})}
								</div>

							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	)
}


function PortfolioPage(props) {

	const items = [
		{
			title: "Le Cercle Du Cashflow",
			alt: "Le Cercle Du Cashflow",
			shortDesc: ["Le réseau social des investisseurs en immobilier.", "Application gratuite créée pour mon propre compte en 2019."],
			longDesc: "TBC",
			img: require('assets/img/portfolio_lcdc2_small-fs8.png'),
			gatsbyImg: props.gqlImages.portfolio_cercle.childImageSharp.fluid,
			links: [{ url: 'https://apps.apple.com/us/app/le-cercle-du-cashflow/id1460008725?l=fr&ls=1', text: "Ouvrir l'application iOS", type: 'ios' }, { url: 'https://play.google.com/store/apps/details?id=com.vasolutions.cashflowmastermind', text: "Ouvrir l'application Android", type: 'android' }],
			technos: ['React Native', 'Node.JS', 'PHP', 'MySQL', 'AWS', 'Docker', 'Nginx', 'iOS App', 'Android App'],
			client: 'Aucun (Startup à mon propre compte)',
			when: '2019',
			myJob: 'Conception, création et implémentation du projet de A à Z en solo.',
			showMacBookFrame: false,
		},
		{
			title: "Hiro",
			alt: "Plateforme de podcasts",
			shortDesc: ["[SaaS] Plateforme de création de podcasts privés permettant aux coachs, entrepreneurs et formateurs de monétiser l'accès à leurs contenus audios.", "Création réalisée en 2020, en freelance, pour le compte d'une société américaine."],
			longDesc: "TBC",
			img: require('assets/img/portfolio_hiro4-fs8.png'),
			gatsbyImg: props.gqlImages.portfolio_hiro.childImageSharp.fluid,
			links: [{ url: 'https://app.hiro.fm', text: 'Visiter le site web' }],
			technos: ['Node.JS', 'Javascript', 'React.JS', 'AWS', 'Stripe API', 'Mongo db', 'Docker', 'Nginx'],
			client: 'Clara White, CEO of Gratitude Labs LLC',
			when: '2020',
			myJob: 'CTO, conception, création et implémentation du projet de A à Z en solo.',
			showMacBookFrame: false,
		},
		{
			title: "Trouver La Bonne Affaire",
			alt: "Trouver La Bonne Affaire",
			shortDesc: ["[SaaS] Logiciel permettant aux investisseurs en immobilier de détecter les bonnes affaires.", "Startup créée pour mon propre compte en 2019."],
			longDesc: "TBC",
			img: require('assets/img/portfolio_tlba4-fs8.png'),//img: 'https://trouverlabonneaffaire.com/assets/img/screen_arrows2.jpg',
			gatsbyImg: props.gqlImages.portfolio_tlba.childImageSharp.fluid,
			links: [{ url: 'https://trouverlabonneaffaire.com/', text: 'Visiter le site web' }],
			technos: ['Node.JS', 'Javascript', 'Stripe API', 'Mongo db', 'Docker', 'Web Scraping', 'Nginx'],
			client: 'Aucun (Startup à mon propre compte)',
			when: '2019',
			myJob: 'Conception, création et implémentation du projet de A à Z en solo.',
			showMacBookFrame: false,
		},
		{
			title: "Applications web Airbus",
			alt: "Applications web Airbus",
			shortDesc: ["Une vingtaine d'applications web développées et pilotées pour Airbus Group pendant mes 10 premières années de carrière chez Sopra Steria (2008-2018)."],
			longDesc: "TBC",
			img: require('assets/img/portfolio_airbusworld.png'),
			gatsbyImg: props.gqlImages.portfolio_airbus.childImageSharp.fluid,
			links: [{ url: 'https://w3.airbus.com/newairbusworld/faces/wcnav_defaultSelection', text: 'Visiter le site web' }],
			technos: ['Java', 'J2EE', 'Oracle SQL', 'C#', 'Javascript', 'UNIX'],
			client: 'Airbus Group',
			when: 'de 2008 à 2018',
			myJob: 'Ingénieur logiciel full stack (5 ans) puis Chef de Projet et Manager (5 ans).',
			showMacBookFrame: false,
		},
		{
			title: "Formations à la programmation",
			alt: "Formations à la programmation",
			shortDesc: ["Transmettre ma passion de la programmation."],
			longDesc: "TBC",
			img: require('assets/img/portfolio_formations-fs8.png'),
			gatsbyImg: props.gqlImages.portfolio_formations.childImageSharp.fluid,
			links: [],
			myJob: null,
			myJobFormations: [
				{ title: <span>Formateur à l'<span className="font-weight-bold">Institut de Poly-Informatique (Master 1 & Master 2)</span></span>, details: [{ title: <span className="font-weight-bold">Docker</span> }, { title: <span className="font-weight-bold">Node.JS</span> }, { title: <span className="font-weight-bold">React Native</span> }] },
				{ title: <span>Formateur <span className="font-weight-bold">Udemy</span></span>, details: [{ title: "Les expressions régulières", url: "https://www.udemy.com/course/expressions-regulieres/?referralCode=DA1325CEF2286A934322" },] },
				{ title: <span>Formateur pour la <span className="font-weight-bold">chaîne YouTube Développeur Libre (~200.000 abonnés)</span></span>, details: [{ title: "Les basiques (HTML/CSS/JS)", url: "https://www.developpeurlibre.com/apprenti-developpeur-web" }, { title: "Le JavaScript Moderne", url: "https://www.developpeurlibre.com/javascript-moderne" }, { title: "React.JS", url: "https://www.developpeurlibre.com/devenir-un-pro-du-react" },] },
			],
			showMacBookFrame: false,
		},
	]

	return (
		<TabContainer {...props} title="Portfolio" subtitle="Quelques-unes de mes réalisations">

			{/* <div className="section">
				<Container>
					<Row>
						<Col className="text-center">
							<h2 className="title mb-5">Quelques-uns de mes travaux :</h2>
							{/* <h5 className="description">
								According to the National Oceanic and Atmospheric
								Administration, Ted, Scambos, NSIDClead scentist, puts the
								potentially record low maximum sea ice extent tihs year down
								to low ice extent in the Pacific and a late drop in ice extent
								in the Barents Sea.
                			</h5> }
						</Col>
					</Row>
					{/* <Row>
						{items.map((item, i) => <PortfolioItemCard key={i} index={i} {...item} />)}
					</Row> }
				</Container>
			</div> */}

			{/* <div className={`section`}>
				<Container>
					<Row>
						<Col className="ml-auto mr-auto text-center">
							<h2 style={{ display: 'inline-block', position: 'relative', transform: 'skewY(-15deg)' }} className="pitch title mb-5">
								<div style={{ width: '100%', height: 8, borderRadius: 4, position: 'absolute', bottom: -10, background: 'rgba(104,83,255,1)', backgroundImage: 'linear-gradient(125deg, rgba(104,83,255,1) 0%, rgba(212,85,254,1) 100%)' }}>
								</div>
								<span style={{ background: 'rgba(104,83,255,1)', backgroundImage: 'linear-gradient(125deg, rgba(104,83,255,1) 0%, rgba(212,85,254,1) 100%)', backgroundClip: 'text', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
									Portfolio
								</span>
							</h2>
							<h2 className="pitch subtitle mt-3 mb-5">— Quelques-unes de mes réalisations —</h2>
						</Col>
					</Row>
				</Container>
			</div> */}

			<SectionSeparator
				title='Portfolio'
				subtitle='Quelques-unes de mes réalisations'
			/>
			{items.map((item, i) => <PortfolioItemSection key={i} index={i} {...item} />)}

		</TabContainer>

	);
}

export default PortfolioPage;
